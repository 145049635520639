import {
  CommunityListRequest,
  CreateCommunityRequest,
  DeleteCommunityRequest,
  UpdateCommunityListRequest,
  VerifyCommunityRequest,
  FreezeCommunityRequest,
  CommunityDetailRequest,
  getCommunityAchievementRequest,
  InputCommunityRequest,
} from "../request/CommunityRequest.js";

export class CommunityControllers {
  communityList = new Array();
  communityDetail = new Object();
  communityDownload = new Array();
  communityAchievement = new Object();
  isError = false;
  isLoading = false;
  errorCause = "";
  errorCode = 0;

  isErrorDelete = false;
  errorCauseDelete = "";
  messageDelete = "";

  isErrorVerify = false;
  errorCauseVerify = "";

  errorDownload = false;
  errorCodeDownload = "";
  errorCauseDownload = "";
  loadingDownload = false;

  isErrorFreeze = false;
  errorCauseFreeze = "";

  constructor() {}

  getCommunityList(page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId) {
    this.setLoading(true);
    const resp = CommunityListRequest(page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setCommunityList(response.data.data);
      })
      .catch((err) => {
        this.setCommunityList([]);
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  getCommunityDetailEdit(id) {
    this.setLoading(true);
    const resp = CommunityDetailRequest(id)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setCommunityDetail(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setCommunityDetail(data) {
    this.communityDetail = data;
  }

  downloadCommunityList(page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId) {
    this.setLoadingDownload(true);
    const resp = CommunityListRequest(page, limit, keyword, startDate, endDate, isReport, brand, category, status, dsoId)
      .then((response) => {
        this.setErrorDownload(false);
        this.setErrorcodeDownload(response.data.code);
        this.setCommunityListDownload(response.data.data);
      })
      .catch((err) => {
        this.setErrorDownload(true);
        this.setErrorCauseDownload(err.response.data.message);
        this.setErrorcodeDownload(err.response.data.code);
      })
      .finally(() => {
        this.setLoadingDownload(false);
      });

    return resp;
  }

  setErrorDownload(status) {
    this.errorDownload = status;
  }

  setErrorcodeDownload(code) {
    this.errorCodeDownload = code;
  }

  setErrorCauseDownload(err) {
    this.errorCauseDownload = err;
  }

  setLoadingDownload(status) {
    this.loadingDownload = status;
  }

  setCommunityListDownload(data) {
    this.communityDownload = data;
  }

  setCommunityList(data) {
    this.communityList = data;
  }

  setCommunityAchievement(data) {
    this.communityAchievement = data;
  }

  createCommunityList(pic, dso, brandId, name, dob, description, category, joinedAt, province, district, subDistrict, village, street, zipCode, logo, socialMedias, totalMember, totalSmoker, totalActiveMember, totalVillagers, rationalConsumptionTarget) {
    this.setLoading(true);
    const resp = CreateCommunityRequest(
      pic,
      dso,
      brandId,
      name,
      dob,
      description,
      category,
      joinedAt,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      logo,
      socialMedias,
      totalMember,
      totalSmoker,
      totalActiveMember,
      totalVillagers,
      rationalConsumptionTarget
    )
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  inputCommunityList(dataInput) {
    this.setLoading(true);
    const resp = InputCommunityRequest(dataInput)
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setLoading(status) {
    this.isLoading = status;
  }

  setError(status) {
    this.isError = status;
  }

  setErrorCause(err) {
    this.errorCause = err;
  }

  setErrorcode(code) {
    this.errorCode = code;
  }

  getCommunityDetail(id) {
    this.communityDetail = this.communityList.find((item) => {
      return item.id === id;
    });
  }

  getCommunityAchievement(dsoId, year) {
    this.setLoading(true);
    const resp = getCommunityAchievementRequest(dsoId, year)
      .then((response) => {
        this.setError(false);
        this.setErrorcode(response.data.code);
        this.setCommunityAchievement(response.data.data);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
        this.setErrorcode(err.response.data.code);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  deleteCommunity(id) {
    this.setLoading(true);
    const resp = DeleteCommunityRequest(id)
      .then((response) => {
        this.setErrorDelete(false);
        this.setMsgDelete(response.data.message);
      })
      .catch((err) => {
        this.setErrorDelete(true);
        this.setErrorCauseDelete(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  setMsgDelete(value) {
    this.messageDelete = value;
  }

  setErrorDelete(status) {
    this.isErrorDelete = status;
  }

  setErrorCauseDelete(err) {
    this.errorCauseDelete = err;
  }

  updateCommunityList(id, totalMember, totalActiveMember, totalSmoker, totalVillagers, rationalConsumptionTarget, pic, dso, brandId, name, dob, description, category, joinedAt, province, district, subDistrict, village, street, zipCode, logo, socialMedias) {
    this.setLoading(true);
    const resp = UpdateCommunityListRequest(
      id,
      totalMember,
      totalActiveMember,
      totalSmoker,
      totalVillagers,
      rationalConsumptionTarget,
      pic,
      dso,
      brandId,
      name,
      dob,
      description,
      category,
      joinedAt,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      logo,
      socialMedias
    )
      .then(() => {
        this.setError(false);
      })
      .catch((err) => {
        this.setError(true);
        this.setErrorCause(err.response.data.message);
      })
      .finally(() => {
        this.setLoading(false);
      });

    return resp;
  }

  verifyCommunity(id) {
    const resp = VerifyCommunityRequest(id)
      .then(() => {
        this.setErrorVerify(false);
      })
      .catch((err) => {
        this.setErrorVerify(true);
        this.setErrorCauseVerify(err.response.data.message);
      });

    return resp;
  }

  setErrorVerify(status) {
    this.isErrorVerify = status;
  }

  setErrorCauseVerify(err) {
    this.errorCauseVerify = err;
  }

  freezeCommunity(id) {
    const resp = FreezeCommunityRequest(id)
      .then(() => {
        this.setErrorFreeze(false);
      })
      .catch((err) => {
        this.setErrorFreeze(true);
        this.setErrorCauseFreeze(err.response.data.message);
      });

    return resp;
  }

  setErrorFreeze(status) {
    this.isErrorFreeze = status;
  }

  setErrorCauseFreeze(err) {
    this.errorCauseVerify(err);
  }
}
