<template>
  <div>
    <v-container class="pt-8">
      <v-row justify="space-between">
        <v-col md="8" sm="12" xs="12" block>
          <h2>Rincian KPI DSO</h2>
        </v-col>
        <v-col md="2" sm="12" xs="12">
          <v-autocomplete :items="years" 
          v-model="chosenYear" label="Tahun" dense></v-autocomplete>

          <!-- <v-btn
            class="float-right"
            @click="download"
            :loading="isLoadingDownload"
            depressed
            color="warning"
            block
          >
            Unduh Excel
          </v-btn> -->
        </v-col>
        <v-col md="2" sm="12">
          <v-btn
            class="float-right"
            :loading="isLoadingPDF"
            :disabled="isLoadingPDF"
            depressed
            color="error"    
            @click="pdf">
              Export PDF
              <template v-slot:loader>
                <span>Exporting...</span>
              </template>
          </v-btn>
        </v-col>
        
      </v-row>
    </v-container>
    <div id="summary-content-chart">
      <v-container class="pt-3">        
        <v-row>
          <v-col md="12" sm="12">
            <v-card v-if="errorCode === 404">
              <v-card-text>Data Tidak Tersedia</v-card-text>
            </v-card>
            <v-card v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">DSO</th>
                      <th class="text-left">Progress KPI</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ kpiSummaryComputed.name }}</td>
                      <td>
                        <ProgressBar :capaian="kpiSummaryComputed.total" :target="15" />
                      </td>
                      <td>
                        <v-card class="border rounded-md grey white--text my-0 pa-2 " height='40px' align="center"
                          justify="center">
                          {{ kpiSummaryComputed.total }}/15
                        </v-card>
                      </td>
                      <!-- <td>
                        <v-btn small icon color="success" dark @click="getdetail(item)">
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <v-card v-if="kpiSummaryComputed">
          <v-card-title class="mt-10">
            Detail KPI
          </v-card-title>
          <DetailSummary 
          :aktivitas="kpiSummaryComputed.detailSummary.aktivitas"
          :konsumsi="kpiSummaryComputed.detailSummary.konsumsi"
          :komunikasi="kpiSummaryComputed.detailSummary.komunikasi"/>
        </v-card>

        <v-card class="mb-10">
          <v-card-title class="mt-10">
            Capaian Kompetensi
          </v-card-title>
          <Competency :chosenYear="chosenYear" :dso="dsoId"/>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import ProgressBar from '../../../components/dashboard/proress-bar.vue'
import { KpiTargetControllers } from '../../../controllers/KpiTargetControllers';
import { getUser, getUserId } from "../../../external/functions/Cookies.js";
import DetailSummary from './detail-kpi-dso.vue'
import Competency from './competency.vue'
import {toJpeg} from "html-to-image";
import {jsPDF} from "jspdf";

export default {
  data: () => ({
    title: "Summary Achievement DSO",
    chosenYear: new Date().getFullYear(),
    kpiTarget: new KpiTargetControllers(),
    isLoadingPDF: false,
    pageScope: {
      page: 1,
      size: 9999
    },
    dso: [
      {
        name: 'DSO Bekasi Test',
        capaian: 11,
        target: 14,
      },
      {
        name: 'DSO Bekasi Test II',
        capaian: 5,
        target: 14,
      },
      {
        name: 'DSO Bekasi Test III',
        capaian: 13,
        target: 14,
      },
      {
        name: 'DSO Bekasi Test IV',
        capaian: 14,
        target: 14,
      },
    ],

  }),
  components: {
    ProgressBar,
    DetailSummary,
    Competency
  },
  computed: {
    kpiSummaryComputed() {
      const kpiTargetArray = this.kpiTarget.kpiSummary
      const kpiTarget = kpiTargetArray[0]

      console.log(kpiTarget)

      return kpiTarget
    },
    dsoId(){
      return getUserId()
    },
    dsoName(){
      return getUser()
    },
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    
    isOldKPI(){
      console.log(this.chosenYear < 2023)
      return this.chosenYear < 2023;
    },
    
  },
  created() {
    this.kpiTarget.getKpiSummary(this.pageScope.page, this.pageScope.size, this.chosenYear)
  },
  watch: {
    chosenYear(newVal) {
      this.kpiTarget.setEmptyKpi()
      this.kpiTarget.getKpiSummary(this.pageScope.page, this.pageScope.size, newVal)
    }
  },
  methods: {
    pdf() {
      this.isLoadingPDF = true
      const self = this
      setTimeout(() => {
        toJpeg(document.getElementById('summary-content-chart'), {backgroundColor: '#FFFFFF'})
        .then(function (dataUrl) {
          var doc = new jsPDF();

          doc.setFontSize(24);
          doc.text(`Chart KPI Summary ${self.dsoName}`, 18, 20);
          doc.setFontSize(8);
          doc.setTextColor("#616161")
          doc.text(`Tahun: ${self.chosenYear}`, 19, 28);
          doc.addImage(dataUrl, "JPEG", 15, 40, 180, 200);
          doc.save("chart-kpi-summary.pdf");
          self.isLoadingPDF = false
        });
      }, 500)
    }
    // getdetail(id) {
    //   this.$router.push({
    //     name: "Detail KPI DSO",
    //     params: {
    //       id: JSON.stringify(id),
    //     },
    //   });
    // },
  }
}
</script>
