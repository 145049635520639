import axios from "axios";
import { AttributeList, VerifyAttribute, AttributeDetail, CreateAttribute, UpdateAttribute, DeleteAttribute } from "../external/services/Endpoints.js";
import { BaseUrl } from "../external/functions/BaseUrl.js"; 
import { getToken } from "../external/functions/Cookies.js";
const headers = {
    'Authorization': `Bearer ${getToken()}`
};
export const AttributeListRequest = (page, limit, keyword, dsoId) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AttributeList(page, limit, keyword, dsoId)}`,
        headers:  headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const CreateAttributeRequest = (
    communityId,
    type,
    description,
    coBrand,
    image,
) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${CreateAttribute()}`,
        data: {
            communityId: communityId,
            type: type,
            description: description,
            coBrand: coBrand,
            image: image,
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const DeleteAttributeRequest = (id) => {
    const resp = axios({
        method: 'delete',
        url: `${BaseUrl()}${DeleteAttribute(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const UpdateAttributeRequest = (
    id,
    type,
    description,
    coBrand,
    image,
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${UpdateAttribute(id)}`,
        data: {
            type: type,
            description: description,
            coBrand: coBrand,
            image: image,
        },
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const AttributeDetailRequest = (id) => {
    const resp = axios({
        method: 'get',
        url: `${BaseUrl()}${AttributeDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}

export const VerifyAttributeRequest = (
    id
) => {
    const resp = axios({
        method: 'put',
        url: `${BaseUrl()}${VerifyAttribute(id)}`,
        headers: headers
    }).then((response) => {
        return response;
    });

    return resp;
}




