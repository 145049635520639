<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="4" sm="12" class="p-5">
              <v-alert text type="info">
                Maksimal File adalah 2 MB
              </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Logo</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload">Batal</v-btn>
                </v-card-text>
                <v-card-text v-else>
                  <input @change="changeMedia" ref="myFileInput" type="file" accept="image/*" />
                </v-card-text>
                <v-img :src="detailCommunity.logo" v-if="thereIsImage"></v-img>
              </v-card>
              <v-alert text type="info" class="mt-5">
                Informasi Sosial Media
                <p class="grey--text">
                  Tekan enter jika akan menambahkan banyak sosial media setiap selesai ketik
                </p>
              </v-alert>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Link Sosial Media</th>
                      <th class="text-right">Hapus</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(socmed, index) in detailCommunity.socialMedias" :key="index">
                      <td>{{ socmed }}</td>
                      <td>
                        <v-btn small class="float-right" icon color="error" dark @click="deleteSocmed(index)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <multiselect class="mt-5" v-model="tag.value" tag-placeholder="Tambah Link Sosial Media" placeholder="Cari atau tambah link sosial media" :options="tag.options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert type="info">Informasi Umum</v-alert>
              <v-autocomplete v-model="detailCommunity.pic.id" :items="pics" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="PIC" required outlined dense></v-autocomplete>

              <v-autocomplete v-model="detailCommunity.dso.id" :items="dsos" item-text="name" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="DSO" required outlined dense></v-autocomplete>

              <v-autocomplete v-model="detailCommunity.brand.id" :items="brands" item-value="id" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Nama Brand" required outlined dense>
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.name }}
                </template>
              </v-autocomplete>

              <v-text-field v-model="detailCommunity.name" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Nama Komunitas" required outlined dense></v-text-field>

              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="detailCommunity.dob" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="detailCommunity.dob" label="Tanggal Lahir Komunitas" v-bind="attrs" readonly v-on="on" required outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="detailCommunity.dob" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(detailCommunity.dob)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-textarea v-model="detailCommunity.description" label="Deskripsi" required outlined dense></v-textarea>

              <v-autocomplete
                v-model="detailCommunity.category"
                :items="categories"
                item-text="key"
                item-value="value"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Kategori"
                required
                outlined
                dense
                @change="setCategory"
              ></v-autocomplete>

              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :return-value.sync="detailCommunity.joinedAt" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="detailCommunity.joinedAt" label="Tanggal Join Komunitas" :rules="[(v) => !!v || 'Kolom wajib diisi']" v-bind="attrs" readonly v-on="on" required outlined dense></v-text-field>
                </template>
                <v-date-picker v-model="detailCommunity.joinedAt" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(detailCommunity.joinedAt)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field v-model="detailCommunity.totalMember" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Jumlah Member" required outlined dense></v-text-field>

              <v-text-field v-model="detailCommunity.totalActiveMember" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Jumlah Member Aktif" required outlined dense></v-text-field>

              <v-text-field
                v-model="detailCommunity.totalVillagers"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Jumlah Warga Desa"
                required
                outlined
                dense
                v-if="detailCommunity.category == 'heppiii'"
              ></v-text-field>

              <!-- <v-text-field
                v-model="detailCommunity.rationalConsumptionTarget"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Target Konsumsi Rasional"
                required
                outlined
                dense
                v-if="isHeppiii"
              ></v-text-field> -->
            </v-col>

            <v-col md="4" sm="12" class="p-5">
              <v-alert outlined type="info" v-if="!isAddressEditable">
                Untuk ubah alamat klik
                <strong style="cursor: pointer" @click="editAddress">Ubah Alamat</strong>
              </v-alert>
              <v-alert outlined type="error" v-else>
                Untuk batal ubah alamat klik
                <strong style="cursor: pointer" @click="cancelEditAddress">Batal Ubah</strong>
              </v-alert>
              <template v-if="addressForm">
                <v-autocomplete
                  v-model="detailCommunity.address.province"
                  :items="provinces"
                  item-text="provinceName"
                  item-value="provinceId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Provinsi"
                  required
                  outlined
                  dense
                  @change="getDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailCommunity.address.district"
                  :items="districts"
                  item-text="districtName"
                  item-value="districtId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kabupaten/Kota"
                  required
                  outlined
                  dense
                  @change="getSubDistrict($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailCommunity.address.subDistrict"
                  :items="subDistricts"
                  item-text="subDistrictName"
                  item-value="subDistrictId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kecamatan"
                  required
                  outlined
                  dense
                  @change="getVillages($event)"
                ></v-autocomplete>

                <v-autocomplete
                  v-model="detailCommunity.address.village"
                  :items="villages"
                  item-text="villageName"
                  item-value="villageId"
                  :rules="[(v) => !!v || 'Kolom wajib diisi']"
                  label="Kelurahan/Kota"
                  required
                  outlined
                  dense
                  @change="getVillagesValue($event)"
                ></v-autocomplete>

                <v-text-field v-model="detailCommunity.address.street" :rules="[(v) => !!v || 'Kolom wajib diisi']" label="Jalan" required outlined dense></v-text-field>

                <v-text-field
                  v-model="detailCommunity.address.zipCode"
                  :rules="[(v) => Number.isInteger(Number(v)) || 'Isian harus angka', (v) => !!v || 'Kolom wajib diisi']"
                  label="Kode Pos"
                  :maxlength="5"
                  required
                  outlined
                  dense
                ></v-text-field>
              </template>
            </v-col>
          </v-row>

          <v-btn :disabled="!valid" color="success" class="mr-4 float-right" @click="submit">
            Ubah
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Brands -->
    <Alert :status="isErrorGetBrands && errorCodeGetBrands !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetBrands" />

    <!-- Error Categories -->
    <Alert :status="isErrorGetCategories && errorCodeGetCategories !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCategories" />

    <!-- Error Users -->
    <Alert :status="isErrorGetUsers && errorCodeGetUsers !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetUsers" />

    <!-- Error Provinces -->
    <Alert :status="isErrorGetProvinces && errorCodeGetProvinces !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetProvinces" />

    <!-- Error Districts -->
    <Alert :status="isErrorGetDistricts && errorCodeGetDistricts !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetDistricts" />

    <!-- Error Sub Districts -->
    <Alert :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetSubDistricts" />

    <!-- Error Villages -->
    <Alert :status="isErrorGetVillages && errorCodeGetVillages !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetVillages" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { UserControllers } from "../../controllers/UserControllers.js";
import { AreaControllers } from "../../controllers/AreaControllers.js";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { BrandControllers } from "../../controllers/BrandControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "create-user",
  data: () => ({
    user: new UserControllers(),
    area: new AreaControllers(),
    community: new CommunityControllers(),
    category: new CategoryControllers(),
    brand: new BrandControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Ubah Komunitas",

    isLoading: false,
    fullPage: true,
    isHeppiii: false,

    form: {
      logo: "",
      logoTemp: [],
    },

    menu: false,
    menu2: false,

    addressFiltered: {
      province: "",
      district: "",
      subDistrict: "",
      village: "",
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    isAddressEditable: false,
    addressForm: false,

    isImageUploaded: false,
    thereIsImage: true,

    tag: {
      value: [],
      options: [],
    },
  }),

  components: {
    Loading,
    Alert,
    Multiselect,
  },

  computed: {
    // users length
    communityLength() {
      return this.community.communityList.length;
    },

    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    // community
    isErrorUpdateCommunity() {
      return this.community.isError;
    },

    errorCauseUpdateCommunity() {
      return this.community.errorCause;
    },

    isLoadingCreateCommunity() {
      return this.community.isLoading;
    },

    // categories
    categories() {
      if (this.detailCommunity.brand.id) {
        return this.category.categoryCommunityList.filter((category) => category.brand.includes(this.detailCommunity.brand.id));
      }
      return this.category.categoryCommunityList;
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },

    // brands
    brands() {
      return this.brand.brandList.filter((brand) => brand.product == "Rokok");
    },

    errorCodeGetBrands() {
      return this.brand.errorCode;
    },

    isErrorGetBrands() {
      return this.brand.isError;
    },

    errorCauseGetBrands() {
      return this.brand.errorCause;
    },

    // users
    pics() {
      return this.user.userList.filter((item) => item.role === "user.pic");
    },

    dsos() {
      return this.user.userListAM;
    },

    errorCodeGetUsers() {
      return this.user.errorCode;
    },

    isErrorGetUsers() {
      return this.user.isError;
    },

    errorCauseGetUsers() {
      return this.user.errorCause;
    },

    // provinces
    provinces() {
      return this.area.provinceList;
    },

    errorCodeGetProvinces() {
      return this.area.errorCodeProvince;
    },

    isErrorGetProvinces() {
      return this.area.isErrorProvince;
    },

    errorCauseGetProvinces() {
      return this.area.errorCauseProvince;
    },

    // districts
    districts() {
      return this.area.districtList;
    },

    errorCodeGetDistricts() {
      return this.area.errorCodeDistrict;
    },

    isErrorGetDistricts() {
      return this.area.isErrorDistrict;
    },

    errorCauseGetDistricts() {
      return this.area.errorCauseDistrict;
    },

    // sub districts
    subDistricts() {
      return this.area.subDistrictList;
    },

    errorCodeGetSubDistricts() {
      return this.area.errorCodeSubDistrict;
    },

    isErrorGetSubDistricts() {
      return this.area.isErrorSubDistrict;
    },

    errorCauseGetSubDistricts() {
      return this.area.errorCauseSubDistrict;
    },

    // village
    villages() {
      return this.area.villageList;
    },

    errorCodeGetVillages() {
      return this.area.errorCodeVillage;
    },

    isErrorGetVillages() {
      return this.area.isErrorVillage;
    },

    errorCauseGetVillages() {
      return this.area.errorCauseVillage;
    },

    // detail  community
    detailCommunity() {
      const community = this.community.communityDetail;

      if (community) {
        return {
          ...community,
          category: community.category? community.category : 0,
          totalMember: community.totalMember? community.totalMember : 0,
          totalActiveMember: community.totalActiveMember? community.totalActiveMember : 0,
          totalSmoker: community.totalSmoker? community.totalSmoker : 0,
          totalVillagers: community.totalVillagers? community.totalVillagers : 0,
          rationalConsumptionTarget: community.rationalConsumptionTarget? community.rationalConsumptionTarget : 0,
          dob: community.dob? community.dob : "",
          description: community.description? community.description : "",
          joinedAt: community.joinedAt? community.joinedAt : "",
          address: {
            province: community.address ? community.address.province : "",
            district: community.address ? community.address.district : "",
            subDistrict: community.address ? community.address.subDistrict : "",
            village: community.address ? community.address.village : "",
            street: community.address ? community.address.street : "",
            zipCode: community.address ? community.address.zipCode : "",
          },
        };
      }

      return null;
    },
  },

  created() {
    this.getUsers();
    this.getDsos();
    this.getProvinces();
    this.getBrands();
    this.getCategories();
    this.getCommunities();
  },

  watch:{
    detailCommunity(e){
      if(e.category=='heppiii'){
        this.isHeppiii = true
      }
    }

  },

  methods: {
    async getCommunities() {
      return this.community.getCommunityDetailEdit(this.$route.params.id);
    },

    back() {
      this.$router.go(-1);
    },

    addTag(newTag) {
      this.tag.value.push(newTag);
      this.tag.options.push(newTag);
    },

    deleteSocmed(index) {
      this.detailCommunity.socialMedias.splice(index, 1);
    },

    getUsers() {
      return this.user.getUserList(this.selectScope.page, this.selectScope.limit);
    },

    getDsos() {
      return this.user.getUserAMList("admin.dso", 1, 1000, "");
    },

    getBrands() {
      return this.brand.getBrandList(this.selectScope.page, this.selectScope.limit);
    },

    getCategories() {
      return this.category.getcategoryCommunityList();
    },

    setCategory(e) {
      this.isHeppiii = e === "heppiii" ? true : false;
    },

    getProvinces() {
      return this.area.getProvinceList(this.selectScope.page, this.selectScope.limit);
    },

    getDistrict(provinceId) {
      const { provinceName } = this.provinces.find((item) => item.provinceId === provinceId);
      this.addressFiltered.province = provinceName;
      return this.area.getDistrictList(provinceId, this.selectScope.page, this.selectScope.limit);
    },

    getSubDistrict(districtId) {
      const { districtName } = this.districts.find((item) => item.districtId === districtId);
      this.addressFiltered.district = districtName;
      return this.area.getSubDistrictList(districtId, this.selectScope.page, this.selectScope.limit);
    },

    getVillages(subDistrictId) {
      const { subDistrictName } = this.subDistricts.find((item) => item.subDistrictId === subDistrictId);
      this.addressFiltered.subDistrict = subDistrictName;
      return this.area.getVillageList(subDistrictId, this.selectScope.page, this.selectScope.limit);
    },

    getVillagesValue(villageId) {
      const { villageName } = this.villages.find((item) => item.villageId === villageId);
      this.addressFiltered.village = villageName;
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.isImageUploaded = true;
          this.thereIsImage = true;
          this.detailCommunity.logo = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.detailCommunity.logo = "";
      this.thereIsImage = false;
      this.isImageUploaded = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      totalMember,
      totalActiveMember,
      totalSmoker,
      totalVillagers,
      rationalConsumptionTarget,
      pic,
      dso,
      brandId,
      name,
      dob,
      description,
      category,
      joinedAt,
      province,
      district,
      subDistrict,
      village,
      street,
      zipCode,
      logo,
      socialMedias,
    ) {
      return this.community.updateCommunityList(
        this.$route.params.id,
        totalMember,
        totalActiveMember,
        totalSmoker,
        totalVillagers,
        rationalConsumptionTarget,
        pic,
        dso,
        brandId,
        name,
        dob,
        description,
        category,
        joinedAt,
        province,
        district,
        subDistrict,
        village,
        street,
        zipCode,
        logo,
        socialMedias
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (this.isImageUploaded) {
        this.isLoading = true;
        await this.uploadMedia("community", this.form.logoTemp);

        this.isLoading = false;
        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isErrorUploadMedia = false;
        } else {
          this.form.logo = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;

        if (this.isAddressEditable) {
          await this.submitProcess(
            parseInt(this.detailCommunity.totalMember),
            parseInt(this.detailCommunity.totalActiveMember),
            parseInt(this.detailCommunity.totalSmoker),
            parseInt(this.detailCommunity.totalVillagers),
            parseInt(this.detailCommunity.rationalConsumptionTarget),
            this.detailCommunity.pic.id,
            this.detailCommunity.dso.id,
            this.detailCommunity.brand.id,
            this.detailCommunity.name,
            this.detailCommunity.dob,
            this.detailCommunity.description,
            this.detailCommunity.category,
            this.detailCommunity.joinedAt,
            this.addressFiltered.province,
            this.addressFiltered.district,
            this.addressFiltered.subDistrict,
            this.addressFiltered.village,
            this.detailCommunity.address.street,
            this.detailCommunity.address.zipCode,
            this.isImageUploaded ? this.form.logo : this.detailCommunity.logo,
            this.detailCommunity.socialMedias.concat(this.tag.value)
          );
        } else {
          await this.submitProcess(
            parseInt(this.detailCommunity.totalMember),
            parseInt(this.detailCommunity.totalActiveMember),
            parseInt(this.detailCommunity.totalSmoker),
            parseInt(this.detailCommunity.totalVillagers),
            this.detailCommunity.pic.id,
            this.detailCommunity.dso.id,
            this.detailCommunity.brand.id,
            this.detailCommunity.name,
            this.detailCommunity.dob,
            this.detailCommunity.description,
            this.detailCommunity.category,
            this.detailCommunity.joinedAt,
            this.detailCommunity.address.province,
            this.detailCommunity.address.district,
            this.detailCommunity.address.subDistrict,
            this.detailCommunity.address.village,
            this.detailCommunity.address.street,
            this.detailCommunity.address.zipCode,
            this.isImageUploaded ? this.form.logo : this.detailCommunity.logo,
            this.detailCommunity.socialMedias.concat(this.tag.value)
          );
        }

        if (this.isErrorUpdateCommunity) {
          this.isLoading = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateCommunity,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil diubah",
          });
          this.tag.value = [];
          this.tag.options = [];
          this.back();
          this.$refs.observer.reset();
        }
      }
    },

    editAddress() {
      this.isAddressEditable = true;
      this.addressForm = true;
      this.getProvinces();
    },

    cancelEditAddress() {
      this.isAddressEditable = false;
      this.addressForm = false;
    },
  },
};
</script>
