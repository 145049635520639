<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Daftarkan Prestasi
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Gambar/Foto Prestasi</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card>
            </v-col>

            <v-col md="6" sm="12" class="p-5">

              <v-autocomplete
                v-model="form.communityId"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.name"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Nama Kompetisi"
                required
                outlined
                dense
              ></v-text-field>

              <v-autocomplete
                v-model="form.year"
                :items="years"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tahun"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-autocomplete
                v-model="chosenAchievement"
                :items="achievements"
                item-text="key"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Skala Prestasi"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.nomination"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Peringkat"
                required
                outlined
                dense
              ></v-text-field>

              <v-checkbox
                v-model="isContract"
                label="Member/Komunitas Pernah Dikontrak oleh Perusahaan"
                required
                outlined
                dense
              ></v-checkbox>

            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 mt-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Alert
      :status="isErrorGetCommunities && errorCodeGetCommunities !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCommunities"
    />




  </v-container>
</template>

<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { AchievementControllers } from "../../controllers/AchievementControllers";
import Swal from "sweetalert2";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "create-achievement",
  data: () => ({
    achievement: new AchievementControllers(),
    community: new CommunityControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Prestasi",

    isLoading: false,
    fullPage: true,
    brandChosen : null,
    isBrandChosen: false,
    achievements:[
      {
      key:'Lokal',
      value: 1
      },
      {
      key:'Kota/Kabupaten',
      value: 2
      },
      {
      key:'Provinsi',
      value: 3
      },
      {
      key:'Nasional',
      value: 4
      }
    ],

    chosenAchievement:0,
    isContract: false,

    form: {
      communityId: "",
      name: "",
      year: "",
      scale: "",
      score: 0,
      nomination: "",
      image: "",
      logoTemp: []
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
  },

  watch:{
    chosenAchievement(newVal){
      const key = this.achievements.find(achiev=>achiev.value==newVal).key
      this.form.scale = key
      if(!this.isContract){
        this.form.score = newVal
      }
    },
    isContract(newVal){
      if(newVal){
        this.form.score = 5
      }else{
        this.form.score = this.chosenAchievement || 0
      }
    }
  },

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    // achievement
    isErrorCreateAchievement() {
      return this.achievement.isError;
    },

    errorCauseCreateAchievement() {
      return this.achievement.errorCause;
    },

    isLoadingCreateCreate() {
      return this.achievement.isLoading;
    },

    // community
    communities() {
      return this.community.communityList.filter(community=>community.category==='e-sport');
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },

    isLoadingCommunities() {
      return this.community.isLoading;
    },


    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },
  },

  created() {
    this.getCommunities();
  },


  methods: {
    back() {
      this.$router.go(-1);
    },

    async getCommunities() {
      return this.community.getCommunityList(1, 9999);
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      communityId,
      name,
      year,
      scale,
      nomination,
      image,
      isContract,
      score
    ) {
      return this.achievement.createAchievementList(
        communityId,
        name,
        year,
        scale,
        nomination,
        image,
        isContract,
        score
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (this.thereIsImage) {
        this.isLoading = true;
        await this.uploadMedia('community', this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.image = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.communityId,
          this.form.name,
          this.form.year,
          this.form.scale,
          this.form.nomination,
          this.form.image,
          this.isContract,
          this.form.score
        );

        if (this.isErrorCreateAchievement) {
          this.isLoading = false;
          this.isErrorCreateAchievement = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateAchievement,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.isErrorCreateAchievement = false;
          this.$refs.observer.reset();
          this.deleteImageUpload()
        }
      }
    },
  },
};
</script>
