<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mb-3">
            Member Aktif Berdasar Area
          </v-list-item-title>
          <v-autocomplete
            v-model="filter.area"
            :items="areaManagerList"
            item-text="name"
            item-value="id"
            label="Pilih Area"
            @change="pickArea"
            outlined
            dense
          ></v-autocomplete>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <VueApexCharts
            v-if="series.length > 0"
            type="donut"
            :options="chartOptions"
            :series="series"
            height="300"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { mapGetters, mapActions } from "vuex";
import { DashboardV2Controller } from "../../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "member-active-area",
  data: () => ({
    dashboard: new DashboardV2Controller(),
    filter: {
      area: "",
    },

    // charts
    series: [],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      colors: [],
      labels: [""],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
              },
              value: {
                show: true,
              },
              total: {
                show: true,
                showAlways: true,
                label: "Total",
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: "#373d3f",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    },

    isLoading: false,
    fullPage: true,
    colorBrand:[
      {
        brand : 'Bold Riders',
        color : "#000000"
      },
      {
        brand : 'Heppiii Community',
        color : "#e2a63d"
      },
      {
        brand : 'Super Family',
        color : "#e23d3d"
      },
      {
        brand : 'Kill The LAst',
        color : "#d99494"
      },
    ],
    colorOthers:{
        brand : 'Others',
        color : "#34bf41"
    } 
  }),

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },

  created() {
    this.getAreaManagers();
  },

  computed: {
    ...mapGetters(["areaManagerList"]),
    isError() {
      return this.dashboard.errorMemberActiveByArea;
    },
    errorCause() {
      return this.dashboard.errorCauseMemberActiveByArea;
    },
    errorCode() {
      return this.dashboard.errorCodeMemberActiveByArea;
    },
  },

  methods: {
    ...mapActions(["getAreaManagers"]),
    async pickArea(area) {
      this.isLoading = true;
      this.series = [];
      this.chartOptions.labels = [];
      await this.dashboard.GetMemberActiveByArea(area);
      this.dashboard.memberActiveByArea.map((item) => {
        this.series.push(item.count);
        this.chartOptions.labels.push(item.brand);
        let brandColor = this.colorBrand.find(datum=> datum.brand == item.brand)
        if(brandColor){
          this.chartOptions.colors.push(brandColor.color)
        }
      });
      this.isLoading = false;

      if (this.series.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "Data Tidak Ditemukan",
        });
      }
    },
  }
};
</script>
