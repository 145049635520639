<template>
  <div>
    <v-row>
      <v-col md="2" sm="12" class="mt-3 mb-2">
        <h5 class="filter-title mb-2">Filter</h5>
        <v-card class="mx-auto" flat>
          <v-card-text>
            <v-row align="center">
              <v-autocomplete
                v-model="filter.area"
                :items="dsos"
                item-text="name"
                item-value="id"
                label="Pilih DSO"
                outlined
                dense
              ></v-autocomplete>
            </v-row>
            <v-row align="center">
              <v-autocomplete
                v-model="filter.year"
                :items="years"
                label="Pilih Tahun"
                outlined
                dense
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-autocomplete
                v-model="filter.brand"
                :items="brands"
                item-text="name"
                item-value="name"
                label="Pilih Brand"
                required
                outlined
                dense
              ></v-autocomplete>
            </v-row>
          </v-card-text>
        </v-card>
        <v-btn
          class="primary mt-2"
          @click="filterData"
          block
          small
          :disabled="disableBtn"
          >Filter</v-btn
        >
      </v-col>
      <v-col md="10" sm="12" class="mt-2">
        <div class="bar-chart">
          <VueApexCharts
            type="bar"
            height="300"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </v-col>
    </v-row>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauase"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { DashboardV2Controller } from "../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "period-report",
  props: {
    dsos: Array,
    brands: Array,
    isLoadingDSOS: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    filter: {
      area: "",
      year: "",
      brand: "",
    },
    // charts
    series: [],
    chartOptions: {
      title: {
        text: 'Tren Pencapaian Atas Sales',
        align: "left",
      },
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      }
    },

    // loading
    isLoading: false,
    fullPage: true,
  }),

  computed: {
    years(){
      const start = 2019
      const end = new Date().getFullYear()

      let year = []

      for(let i = start;i<=end;i++){
        year.push(i)
      }

      return year
    },
    disableBtn() {
      return (
        this.filter.area === "" ||
        this.filter.year === "" ||
        this.filter.brand === ""
      );
    },
    isError() {
      return this.dashboard.errorSalesPeriod;
    },
    errorCauase() {
      return this.dashboard.errorCauseSalesPeriod;
    },
  },

  methods: {
    async filterData() {
      this.isLoading = true;
      this.series = [];
      this.chartOptions.xaxis.categories = [];
  

      await this.dashboard.GetPeriodSales(
        this.filter.area, this.filter.year, this.filter.brand
      );
      this.chartOptions.xaxis.categories = this.dashboard.salesPeriod.map(item => item.month)
      const dataSeries = this.dashboard.salesPeriod.map(item => item.total);
      this.series.push({
        name: 'Sales',
        data: dataSeries
      });
      this.chartOptions.title.text = `Tren Pencapaian Atas Sales Tahun ${this.filter.year}`;
      this.isLoading = false;
    },
  },

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },
};
</script>
