<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Buat Atribute
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Gambar/Foto Atribut</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="logoDisplay" v-if="thereIsImage"></v-img>
              </v-card>

              <v-checkbox
                v-model="form.coBrand"
                label="Terdapat Co-Branding"
                color="primary"
                outlined
                dense
              ></v-checkbox>
            </v-col>

            <v-col md="6" sm="12" class="p-5">
              <v-autocomplete
                v-model="brandChosen"
                :items="allBrand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.name }}
              </template>
              </v-autocomplete>

              <v-autocomplete
                :disabled="!isBrandChosen"
                v-model="form.communityId"
                :items="communities"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Komunitas"
                required
                outlined
                dense
              ></v-autocomplete>

              <v-text-field
                v-model="form.type"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tipe Atribut"
                required
                outlined
                dense
              ></v-text-field>

              <v-textarea
                v-model="form.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                required
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetCommunities && errorCodeGetCommunities !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetCommunities"
    />
  </v-container>
</template>

<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { AttributeControllers } from "../../controllers/AttributeControllers";
import Swal from "sweetalert2";
import { BrandControllers } from "../../controllers/BrandControllers";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "create-attribute",
  data: () => ({
    attribute: new AttributeControllers(),
    brand: new BrandControllers(),
    community: new CommunityControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Attribute",

    isLoading: false,
    fullPage: true,
    brandChosen : null,
    isBrandChosen: false,

    form: {
      communityId: "",
      type: "",
      description: "",
      coBrand:false,
      image: "",
      logoTemp: []
    },

    selectScope: {
      page: 1,
      limit: 600,
    },

    logoDisplay: null,
    thereIsImage: false,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // attribute
    isErrorCreateAttribute() {
      return this.attribute.isError;
    },

    errorCauseCreateAttribute() {
      return this.attribute.errorCause;
    },

    isLoadingCreateCreate() {
      return this.attribute.isLoading;
    },

    // community
    communities() {
      return this.community.communityList;
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },

    isLoadingCommunities() {
      return this.community.isLoading;
    },


    // allbrand
    allBrand() {
      return this.brand.brandList;
    },

    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },
  },

  created() {
    this.getAllBrand();
  },

  watch:{
    brandChosen(newBrand){
      if(newBrand.id){
        this.isBrandChosen=true
      }else{
        this.isBrandChosen=false
      }
      this.getCommunities({
        page:this.selectScope.page,
        limit:this.selectScope.limit,
        brand: newBrand.brandCommunity})
    }
  },

  methods: {
    back() {
      this.$router.go(-1);
    },

    async getCommunities(payload) {
      return this.community.getCommunityList(
        payload.page,
        payload.limit,
        "",
        "",
        "", 
        false, 
        payload.brand, 
        ""
      );
    },

    getAllBrand() {
      return this.brand.getBrandList(this.selectScope.page, this.selectScope.limit)
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.thereIsImage = true;
          this.logoDisplay = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.logoDisplay = null;
      this.thereIsImage = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      communityId,
      type,
      description,
      coBrand,
      image,
    ) {
      return this.attribute.createAttributeList(
        communityId,
        type,
        description,
        coBrand,
        image,

        
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();
      if (this.thereIsImage) {
        this.isLoading = true;
        await this.uploadMedia('community', this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.image = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.form.communityId,
          this.form.type,
          this.form.description,
          this.form.coBrand,
          this.form.image,
        );

        if (this.isErrorCreateAttribute) {
          this.isLoading = false;
          this.isErrorCreateAttribute = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseCreateAttribute,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.isErrorCreateAttribute = false;
          this.$refs.observer.reset();
          this.back();
        }
      }
    },
  },
};
</script>
