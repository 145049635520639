<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mb-3">
            Member Berdasar Usia
          </v-list-item-title>
          <v-autocomplete
            v-model="filter.area"
            :items="dsos"
            item-text="name"
            item-value="id"
            label="Pilih DSO"
            @change="pickArea"
            outlined
            dense
          ></v-autocomplete>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <VueApexCharts
            v-if="series.length > 0"
            type="donut"
            :options="chartOptions"
            :series="series"
            height="300"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { DashboardV2Controller } from "../../../../../controllers/DashboardV2Controller.js";
import Alert from "../../../../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "member-active-area",
  props: {
    dsos: Array
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    filter: {
      area: "",
    },

    // charts
    series: [],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [""],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },

    isLoading: false,
    fullPage: true,
  }),

  components: {
    VueApexCharts,
    Alert,
    Loading,
  },

  computed: {
    isError() {
      return this.dashboard.errorMemberActiveByAge;
    },
    errorCause() {
      return this.dashboard.errorCauseMemberActiveByAge;
    },
    errorCode() {
      return this.dashboard.errorCodeMemberActiveByAge;
    },
  },

  methods: {
    async pickArea(area) {
      this.isLoading = true;
      this.series = [];
      this.chartOptions.labels = [];
      await this.dashboard.GetMemberActiveByAge(area);
      this.dashboard.memberActiveByAge.map((item) => {
        this.series.push(Number(item.value));
        this.chartOptions.labels.push(item.title);
      });
      this.isLoading = false;
    },
  }
};
</script>
