<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="4" sm="12">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col md="3" sm="12">
        <v-btn v-if="getRole.role.create" class="float-right" depressed color="primary" @click="createCommunity" block>
          Buat Komunitas
        </v-btn>
      </v-col>
      <v-col md="2" sm="12">
        <v-btn class="float-right" @click="download" :loading="isLoadingDownload" depressed color="warning" block>
          Unduh Excel
        </v-btn>
      </v-col>
      <v-col md="3" sm="12"> <v-text-field @keyup.enter="debounceSearch" @input="debounceSearchEmpty" v-model="searchField" label="Pencarian" placeholder="Ketik di sini lalu enter/return" outlined dense></v-text-field></v-col>
      <v-col md="3" sm="4">
        <v-autocomplete v-model="brand" :items="brands" item-text="key" item-value="value" label="Brand" dense outlined></v-autocomplete>
      </v-col>
      <v-col md="3" sm="4">
        <v-autocomplete v-model="categoryCommunity" :items="categoryCommunities" item-text="key" item-value="value" label="Kategori" dense outlined></v-autocomplete>
      </v-col>
      <v-col md="3" sm="4">
        <v-autocomplete v-model="status" :items="statusMatrixKisi" item-text="key" item-value="value" label="Hasil Matriks" dense outlined></v-autocomplete>
      </v-col>
      <v-col md="3" sm="12" v-if="getRole.role.filter">
        <v-autocomplete :items="allUserAM" v-model="dso" item-text="name" item-value="id" @change="pickArea" label="Pilih DSO" dense block right outlined></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="pt-0 mt-0" v-if="!isLoadingCommunities">
      <v-col md="12" sm="12">
        <v-card v-if="errorCodeGetCommunities === 404">
          <v-card-text>Data Tidak Tersedia</v-card-text>
        </v-card>
        <v-card v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nama Komunitas</th>
                  <th class="text-left">Brand Komunitas</th>
                  <th class="text-left">Kategori Komunitas</th>
                  <th class="text-left">Hasil Matriks Kisi</th>
                  <th class="text-left">Dibuat Pada</th>
                  <th class="text-left">Verifikasi</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Medsos Performance</th>
                  <th class="text-left">Rincian</th>
                  <th class="text-left" v-if="getRole.role.update">Ubah</th>
                  <th class="text-left" v-if="getRole.role.del">Hapus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in communities" :key="item.id">
                  <td>
                    <router-link style="text-decoration: none" :to="{ name: 'Member', params: { id: item.id } }"> {{ item.name }}</router-link>
                  </td>
                  <td>{{ item.brand.brandCommunity }}</td>
                  <td>{{ item.categoryObj ? item.categoryObj.key : item.category }}</td>
                  <td>{{ item.status ? item.status : "No Data" }}</td>
                  <td>{{ item.createdAt | getNormalDate }}</td>
                  <td v-if="item.isVerified">
                    <v-chip color="success"> Terverifikasi </v-chip>
                  </td>
                  <td v-else>
                    <v-btn :disabled="!getRole.role.verify ? true : false" small outlined color="success" dark @click="verify(item.id)">
                      Verifikasi
                    </v-btn>
                  </td>
                  <td v-if="item.isFreeze">
                    <v-chip v-if="!getRole.role.freeze" color="error">
                      Dibekukan
                    </v-chip>
                    <v-btn v-else :disabled="!getRole.role.freeze ? true : false" small outlined color="success" dark @click="activated(item.id)">
                      Aktifkan
                    </v-btn>
                  </td>
                  <td v-else>
                    <v-chip v-if="!getRole.role.freeze" color="success">
                      Aktif
                    </v-chip>
                    <v-btn v-else :disabled="!getRole.role.freeze ? true : false" small outlined color="error" dark @click="freeze(item.id)">
                      Bekukan
                    </v-btn>
                  </td>
                  <td>
                    <v-btn small icon color="success" dark @click="performance(item.id)">
                      <v-icon>mdi-forum</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn small icon color="success" dark @click="getdetail(item.id)">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.update">
                    <v-btn small icon color="warning" dark @click="edit(item.id)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="getRole.role.del">
                    <v-btn small icon color="error" dark @click="deleteCommunity(item.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="12" sm="12">
        <span class="mr-4 grey--text"> Tampil {{ pages.start }} - {{ pages.end }} </span>
        <v-btn dark color="blue darken" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn dark color="blue darken" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col md="12" sm="12">
        <v-card>
          <v-card-text>Memuat Data...</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <Detail :isShow="isDetail" :data="communityDetail" @hide="updateModal" />

    <Alert :status="isErrorGetCommunities && errorCodeGetCommunities !== 404" icon="error" title="KESALAHAN" :msg="errorCauseGetCommunities" />

    <Alert :status="isErrorDelete" icon="error" title="KESALAHAN" :msg="errorCauseDelete" />

    <Alert :status="isErrorVerify" icon="error" title="KESALAHAN" :msg="errorCauseVerify" />

    <Alert :status="isErrorFreeze" icon="error" title="KESALAHAN" :msg="errorCauseFreeze" />

    <Alert :status="isErrorDownload && isErrorCodeDownload !== 404" icon="error" title="KESALAHAN" :msg="isErrorCauseDownload" />
  </v-container>
</template>
<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import { formatDate } from "../../external/functions/Utilization.js";
import "vue-loading-overlay/dist/vue-loading.css";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { CategoryControllers } from "../../controllers/CategoryControllers.js";
import { UserControllers } from "../../controllers/UserControllers";
import Detail from "./community-detail";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import { getRole } from "../../external/functions/Cookies";

export default {
  name: "community-list",
  data: () => ({
    community: new CommunityControllers(),
    category: new CategoryControllers(),
    userControllers: new UserControllers(),
    title: "Komunitas",
    dso: "",
    communityTable: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    brand: "",
    categoryCommunity: "",
    status: "",
    pages: {
      start: 1,
      end: 10,
    },

    isLoading: false,
    fullPage: true,

    isDetail: false,

    search: {
      debounce: null,
    },

    dialogDownload: false,

    startDate: false,
    endDate: false,
    filter: {
      startDate: "",
      endDate: "",
      size: null,
    },
    searchField: "",
  }),

  components: {
    Alert,
    Loading,
    Detail,
  },

  created() {
    this.getCommunities();
    this.getCategories();
    this.getBrandCommunityList();
    if (getRole() !== "admin.dso") {
      this.userControllers.getUserAMList("admin.dso", 1, 1000, "");
    }
  },

  watch: {
    brand() {
      this.getCommunitiesFilter();
    },
    categoryCommunity() {
      this.getCommunitiesFilter();
    },
    status() {
      this.getCommunitiesFilter();
    },
    dso() {
      this.getCommunitiesFilter();
    },
  },

  computed: {
    getRole() {
      return this.$route.meta;
    },

    allUserAM() {
      let arrDso = this.userControllers.userListAM;
      arrDso.unshift({ name: "Semua DSO", id: "" });
      return arrDso;
    },

    // community
    communities() {
      return this.community.communityList;
    },

    errorCodeGetCommunities() {
      return this.community.errorCode;
    },

    isErrorGetCommunities() {
      return this.community.isError;
    },

    errorCauseGetCommunities() {
      return this.community.errorCause;
    },

    isLoadingCommunities() {
      return this.community.isLoading;
    },

    communityDetail() {
      return this.community.communityDetail;
    },

    // delete
    isErrorDelete() {
      return this.community.isErrorDelete;
    },

    errorCauseDelete() {
      return this.community.errorCauseDelete;
    },

    isLoadingDelete() {
      return this.community.isLoading;
    },

    // verify
    isErrorVerify() {
      return this.community.isErrorVerify;
    },

    errorCauseVerify() {
      return this.community.errorCauseVerify;
    },

    // freeze
    isErrorFreeze() {
      return this.community.isErrorFreeze;
    },

    errorCauseFreeze() {
      return this.community.errorCauseVerify;
    },

    // download
    dataDownload() {
      return this.community.communityDownload;
    },

    isErrorDownload() {
      return this.community.errorDownload;
    },

    isErrorCauseDownload() {
      return this.community.errorCauseDownload;
    },

    isLoadingDownload() {
      return this.community.loadingDownload;
    },

    isErrorCodeDownload() {
      return this.community.errorCodeDownload;
    },

    // categories
    categoryCommunities() {
      let categories = this.category.categoryCommunityList;
      categories.unshift({
        key: "Semua Kategori",
        value: "",
      });
      return categories;
    },

    brands() {
      let brands = this.category.brandList;
      brands.unshift({
        key: "Semua Brand",
        value: "",
      });
      return brands;
    },

    statusMatrixKisi() {
      return [
        { key: "Semua Hasil Matrix", value: "" },
        { key: "OPPORTUNISTIC", value: "OPPORTUNISTIC" },
        { key: "MAINTENANCE", value: "MAINTENANCE" },
        { key: "DEVELOPMENT", value: "DEVELOPMENT" },
        { key: "KEY", value: "KEY" },
      ];
    },

    errorCodeGetCategories() {
      return this.category.errorCode;
    },

    isErrorGetCategories() {
      return this.category.isError;
    },

    errorCauseGetCategories() {
      return this.category.errorCause;
    },
  },

  filters: {
    getNormalDate(value) {
      return formatDate(value);
    },
  },

  methods: {
    async getCommunitiesFilter() {
      return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
    },

    async getCommunities() {
      return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
    },

    async formerPage() {
      if (this.pages.start > 10) {
        this.isLoading = this.isLoadingCommunities;
        this.pages.start -= 10;
        this.pages.end -= 10;

        this.communityTable.page -= 1;

        await this.getCommunities(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
        if (this.isErrorGetCommunities) {
          Swal.fire({
            icon: "warning",
            text: "Data sebelumnya tidak tersedia",
          });
        }
      }
    },

    async nextPage() {
      this.isLoading = this.isLoadingCommunities;
      this.pages.start += 10;
      this.pages.end += 10;

      this.communityTable.page += 1;

      await this.getCommunities(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
      if (this.isErrorGetCommunities) {
        Swal.fire({
          icon: "warning",
          text: "Data selanjutnya tidak tersedia",
        });
      }
    },

    getCategories() {
      return this.category.getcategoryCommunityList();
    },

    getBrandCommunityList() {
      return this.category.getBrandList();
    },

    createCommunity() {
      this.$router.push({
        name: "Create Community",
      });
    },

    getdetail(id) {
      this.isDetail = true;
      return this.community.getCommunityDetail(id);
    },

    updateModal(status) {
      this.isDetail = status;
    },

    deleteUser(id) {
      return id;
    },

    deleteCommunity(id) {
      Swal.fire({
        title: "Yakin akan menghapus?",
        text: "Anda tidak dapat mengembalikan data tersebut!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.community.deleteCommunity(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit);
        });
    },

    async debounceSearch() {
      this.pages.start = 1;
      this.pages.end = 10;

      this.communityTable.page = 1;
      this.communityTable.limit = 10;
      this.communityTable.keyword = this.searchField;

      await this.getCommunities(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
    },

    debounceSearchEmpty(event) {
      clearTimeout(this.debounce);
      this.search.debounce = setTimeout(async () => {
        if (event.length === 0) {
          this.pages.start = 1;
          this.pages.end = 10;

          this.communityTable.page = 1;
          this.communityTable.limit = 10;
          this.communityTable.keyword = event;

          await this.getCommunities(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", false, this.brand, this.categoryCommunity, this.status, this.dso);
        }
      }, 500);
    },

    performance(id) {
      this.$router.push({
        name: "Community Performance",
        params: {
          id: id,
        },
      });
    },
    edit(id) {
      this.$router.push({
        name: "Edit Community",
        params: {
          id: id,
        },
      });
    },

    verify(id) {
      Swal.fire({
        title: "Yakin akan verifikasi komunitas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, verifikasi komunitas",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.community.verifyCommunity(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit);
        });
    },

    async downloadState(size, startDate, endDate) {
      return this.community.downloadCommunityList(1, size, "", startDate, endDate, true, this.brand, this.categoryCommunity, this.status, this.dso);
    },

    async download() {
      await this.community.downloadCommunityList(this.communityTable.page, this.communityTable.limit, this.communityTable.keyword, "", "", true, this.brand, this.categoryCommunity, this.status, this.dso);
      if (this.dataDownload.length > 0) {
        const dataDownload = [];
        for (const item of this.dataDownload) {
          const dataI = {
            project: item.project ? item.brand.project.name : "",
            "nama komunitas": item.name,
            "brand komunitas": item.brand ? item.brand.name : "",
            kategori: item.category,
            "dibuat pada": item.createdAt,
            terverifikasi: item.isVerified ? "Yes" : "No",
            pic: item.pic ? item.pic.name : "",
            dso: item.dso ? item.dso.name : "",
            "dibuat oleh": item.createdBy ? item.createdBy.name : "",
            area: item.area,
            alamat: item.address ? `${item.address.street}, ${item.address.village}, ${item.address.subDistrict}, ${item.address.district}, ${item.address.province}, ${item.address.zipCode}` : "",
            "total anggota": item.totalMember,
            "total aktif member": item.totalActiveMember,
            "total perokok": item.totalSmoker,
            "total warga desa": item.category == "heppiii" ? item.totalVillagers : "",
            "total medsos": item.socialMedias ? item.socialMedias.length : 0,
            deskripsi: item.description,
            "tanggal lahir komunitas": item.dob,
          };

          if (item.socialMedias && item.socialMedias.length > 0) {
            const sosmed = item.socialMedias;
            for (let i = 0; i < sosmed.length; i++) {
              dataI[`link sosmed ${i + 1}`] = sosmed[i];
            }
          }

          dataDownload.push(dataI);
        }

        const data = XLSX.utils.json_to_sheet(dataDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, `Report Community List.xlsx`);
      } else {
        Swal.fire({
          text: "Data Tidak ditemukan",
          icon: "warning",
        });
      }
    },

    freeze(id) {
      Swal.fire({
        title: "Yakin akan bekukan komunitas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, bekukan komunitas",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.community.freezeCommunity(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit);
        });
    },

    activated(id) {
      Swal.fire({
        title: "Yakin akan aktifkan komunitas?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, aktifkan komunitas",
        cancelButtonText: "Batal",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            return this.community.freezeCommunity(id);
          }
        })
        .finally(() => {
          this.isLoading = false;
          return this.community.getCommunityList(this.communityTable.page, this.communityTable.limit);
        });
    },
  },
};
</script>
