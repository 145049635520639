<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mb-3">
            Komunitas Berdasar Usia
          </v-list-item-title>
          <v-autocomplete
            v-model="filter.area"
            :items="dsos"
            @change="pickArea"
            item-text="name"
            item-value="id"
            label="Pilih DSO"
            outlined
            dense
          ></v-autocomplete>
        </v-list-item-content>
      </v-list-item>
      <v-card-text>
        <v-row align="center">
          <VueApexCharts
            v-if="series.length > 0"
            type="donut"
            :options="chartOptions"
            :series="series"
            height="300"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <Alert
      :status="isError"
      icon="error"
      title="KESALAHAN"
      :msg="errorCause"
    />
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DashboardV2Controller } from "../../../../../../controllers/DashboardV2Controller.js"
import Alert from "../../../../../../components/alert";
import Swal from "sweetalert2";

export default {
  name: "total-brand",
  props: {
    dsos: Array
  },
  data: () => ({
    dashboard: new DashboardV2Controller(),
    filter: {
      area: "",
    },

    // charts
    series: [],
    chartOptions: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: [''],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },

    isLoading: false,
    fullPage: true,
  }),

  components: {
    VueApexCharts,
    Loading,
    Alert
  },

  computed: {
    isError() {
      return this.dashboard.errorBrandByAgeDSO;
    },
    errorCause() {
      return this.dashboard.errorCauseBrandByAgeDSO;
    },
    errorCode() {
      return this.dashboard.errorCodeBrandByAgeDSO;
    },
  },

  methods: {
    async pickArea(area) {
      this.isLoading = true;
      this.series = [];
      this.chartOptions.labels = [];
      await this.dashboard.GetBrandByAgeDSO(area);
      this.dashboard.brandByAgeDSO.map((item) => {
        this.series.push(Number(item.value));
        this.chartOptions.labels.push(item.title);
      })
      this.isLoading = false;

      if (this.series.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "Data Tidak Ditemukan",
        });
      }
    }
  }
};
</script>
