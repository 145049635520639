import {
    AttributeListRequest,
    VerifyAttributeRequest,
    AttributeDetailRequest,
    CreateAttributeRequest,
    UpdateAttributeRequest,
    DeleteAttributeRequest
} from "../request/AttributeRequest.js";

export class AttributeControllers {
    attributeList = new Array();
    attributeDetail = new Object();
    attributeDownload = new Array();

    isError = false;
    isLoading = false;
    errorCause = "";
    errorCode = 0;

    isErrorDelete = false;
    errorCauseDelete = "";
    messageDelete = "";

    isErrorVerify = false;
    errorCauseVerify = "";

    errorDownload = false;
    errorCodeDownload = "";
    errorCauseDownload = "";
    loadingDownload = false;

    constructor() {}

    getAttributeList(page, limit, keyword, dsoId) {
        this.setLoading(true);
        const resp = AttributeListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setAttributeList(response.data.data);
            }).catch(err => {
                this.setAttributeList([]);
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    getAttributeDetailEdit(id) {
        this.setLoading(true);
        const resp = AttributeDetailRequest(id)
            .then((response) => {
                this.setError(false);
                this.setErrorcode(response.data.code);
                this.setAttributeDetail(response.data.data);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
                this.setErrorcode(err.response.data.code);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setAttributeList(data) {
        this.attributeList = data;
    }

    setAttributeDetail(data) {
        this.attributeDetail = data;
    }

    setLoading(status) {
        this.isLoading = status;
    }

    setError(status) {
        this.isError = status;
    }

    setErrorCause(err) {
        this.errorCause = err;
    }

    setErrorcode(code) {
        this.errorCode = code;
    }

    verifyAttribute(
        id
    ) {
        const resp = VerifyAttributeRequest(
                id
            )
            .then(() => {
                this.setErrorVerify(false);
            }).catch(err => {
                this.setErrorVerify(true);
                this.setErrorCauseVerify(err.response.data.message);
            });

        return resp;
    }

    setErrorVerify(status) {
        this.isErrorVerify = status;
    }

    setErrorCauseVerify(err) {
        this.errorCauseVerify = err;
    }

    getAttributeDetail(id) {
        this.attributeDetail = this.attributeList.find(item => {
            return item.id === id
        });
    }

    downloadAttributeList(page, limit, keyword, dsoId) {
        this.setLoadingDownload(true);
        const resp = AttributeListRequest(page, limit, keyword, dsoId)
            .then((response) => {
                this.setErrorDownload(false);
                this.setErrorcodeDownload(response.data.code);
                this.setAttributeListDownload(response.data.data);
            }).catch(err => {
                this.setAttributeListDownload([]);
                this.setErrorDownload(true);
                this.setErrorCauseDownload(err.response.data.message);
                this.setErrorcodeDownload(err.response.data.code);
            }).finally(() => {
                this.setLoadingDownload(false);
            });

        return resp;
    }

    setErrorDownload(status) {
        this.errorDownload = status;
    }

    setErrorcodeDownload(code) {
        this.errorCodeDownload = code;
    }

    setErrorCauseDownload(err) {
        this.errorCauseDownload = err;
    }

    setLoadingDownload(status) {
        this.loadingDownload = status;
    }

    setAttributeListDownload(data) {
        this.attributeDownload = data;
    }

    createAttributeList(
        communityId,
        type,
        description,
        coBrand,
        image,
    ) {
        this.setLoading(true);
        const resp = CreateAttributeRequest(
            communityId,
            type,
            description,
            coBrand,
            image,
            
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    deleteAttribute(id) {
        this.setLoading(true);
        const resp = DeleteAttributeRequest(id)
            .then((response) => {
                this.setErrorDelete(false);
                this.setMsgDelete(response.data.message);
            }).catch(err => {
                this.setErrorDelete(true);
                this.setErrorCauseDelete(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }

    setMsgDelete(value) {
        this.messageDelete = value;
    }

    setErrorDelete(status) {
        this.isErrorDelete = status;
    }

    setErrorCauseDelete(err) {
        this.errorCauseDelete = err;
    }

    updateAttributeList(
        id,
        type,
        description,
        coBrand,
        image,
    ) {
        this.setLoading(true);
        const resp = UpdateAttributeRequest(
            id,
            type,
            description,
            coBrand,
            image,
        )
            .then(() => {
                this.setError(false);
            }).catch(err => {
                this.setError(true);
                this.setErrorCause(err.response.data.message);
            }).finally(() => {
                this.setLoading(false);
            });

        return resp;
    }


}