<template>
  <v-container class="pt-8">
    <v-row>
      <v-col md="12">
        <h2>
          <v-btn icon color="primary" @click="back">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          Edit Atribut
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" sm="12" class="p-5">
        <v-form ref="observer" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" sm="12" class="p-5">
              <v-alert text type="info"> Maksimal File adalah 2 MB </v-alert>
              <v-card>
                <v-card-title>
                  <p>Unggah Gambar/Foto Atribut</p>
                </v-card-title>
                <v-card-text v-if="form.logoTemp.length > 0">
                  <v-btn depressed color="error" @click="deleteImageUpload"
                    >Batal</v-btn
                  >
                </v-card-text>
                <v-card-text v-else>
                  <input
                    @change="changeMedia"
                    ref="myFileInput"
                    type="file"
                    accept="image/*"
                  />
                </v-card-text>
                <v-img :src="detailAttribute.image" v-if="thereIsImage"></v-img>
              </v-card>

              <v-checkbox
                v-model="detailAttribute.coBrand"
                label="Terdapat Co-Branding"
                color="primary"
                outlined
                dense
              ></v-checkbox>
            </v-col>

            <v-col md="6" sm="12" class="p-5">
              <!-- <v-autocomplete
                v-model="brandChosen"
                :items="allBrand"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Brand"
                required
                outlined
                dense
              >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }} - {{ data.item.brandCommunity }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }} - {{ data.item.brandCommunity }}
              </template>
              </v-autocomplete> -->

              <v-list-item two-line v-if="detailAttribute.community" class="mb-5">
                <v-list-item-content>
                  <v-list-item-title>Komunitas</v-list-item-title>
                  <v-list-item-subtitle>{{ detailAttribute.community.name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-text-field
                v-model="detailAttribute.type"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Tipe Atribut"
                required
                outlined
                dense
              ></v-text-field>

              <v-textarea
                v-model="detailAttribute.description"
                :rules="[(v) => !!v || 'Kolom wajib diisi']"
                label="Deskripsi"
                required
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="submit"
          >
            Buat
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

    <!-- Error Provinces -->
    <Alert
      :status="isErrorGetProvinces && errorCodeGetProvinces !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetProvinces"
    />

    <!-- Error Districts -->
    <Alert
      :status="isErrorGetDistricts && errorCodeGetDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetDistricts"
    />

    <!-- Error Sub Districts -->
    <Alert
      :status="isErrorGetSubDistricts && errorCodeGetSubDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetSubDistricts"
    />

    <!-- Error Villages -->
    <Alert
      :status="isErrorGetVillages && errorCodeGetVillages !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetVillages"
    />

    <!-- Error All Districts -->
    <Alert
      :status="isErrorGetAllDistricts && errorCodeGetAllDistricts !== 404"
      icon="error"
      title="KESALAHAN"
      :msg="errorCauseGetAllDistricts"
    />
  </v-container>
</template>

<script>
import Alert from "../../components/alert";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { AttributeControllers } from "../../controllers/AttributeControllers";
import Swal from "sweetalert2";
import { BrandControllers } from "../../controllers/BrandControllers";
import { CommunityControllers } from "../../controllers/CommunityControllers.js";
import { MediaControllers } from "../../controllers/MediaControllers.js";

export default {
  name: "create-attribute",
  data: () => ({
    attribute: new AttributeControllers(),
    brand: new BrandControllers(),
    community: new CommunityControllers(),
    media: new MediaControllers(),
    valid: true,
    title: "Buat Attribute",

    isLoading: false,
    fullPage: true,
    brandChosen : null,
    isBrandChosen: false,

    form: {
      image: "",
      logoTemp: []
    },

    selectScope: {
      page: 1,
      limit: 600,
    },
    isImageUploaded: false,
    thereIsImage: true,
  }),

  components: {
    Loading,
    Alert,
  },

  computed: {
    // attribute
    isErrorUpdateAttribute() {
      return this.attribute.isError;
    },

    errorCauseUpdateAttribute() {
      return this.attribute.errorCause;
    },

    isLoadingCreateCreate() {
      return this.attribute.isLoading;
    },


    // allbrand
    allBrand() {
      return this.brand.brandList;
    },

    // media
    medias() {
      return this.media.successMsg;
    },

    isErrorUploadMedia() {
      return this.media.isError;
    },

    errorCauseMediaUpload() {
      return this.media.errorCause;
    },

    isLoadingUploadMedia() {
      return this.media.isLoading;
    },

    detailAttribute() {
      const attribute = this.attribute.attributeDetail;
      if(attribute){
        return{
          ...attribute,
          type: attribute.type? attribute.type : '',
          description: attribute.description? attribute.description : '',
          coBrand: attribute.isLogo ? attribute.isLogo : false,

        }
      }
      return null
    }
  },

  created() {
    this.getAttribute()
  },

  // watch:{
  //   brandChosen(newBrand){
  //     if(newBrand.id){
  //       this.isBrandChosen=true
  //     }else{
  //       this.isBrandChosen=false
  //     }
  //     this.getCommunities({
  //       page:this.selectScope.page,
  //       limit:this.selectScope.limit,
  //       brand: newBrand.brandCommunity})
  //   }
  // },

  methods: {
    async getAttribute() {
      return this.attribute.getAttributeDetailEdit(
        this.$route.params.id
      );
    },
    
    back() {
      this.$router.go(-1);
    },

    changeMedia(e) {
      const selectImage = e.target.files[0];
      this.createBase64Image(selectImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      const sizeLimit = fileObject.size / 1000;
      if (sizeLimit > 2000) {
        Swal.fire({
          icon: "error",
          title: "KESALAHAN",
          text: "File tidak boleh lebih dari 2 MB",
        });
        this.$refs.myFileInput.value = "";
      } else {
        reader.readAsDataURL(fileObject);

        reader.onload = async (e) => {
          this.isImageUploaded = true;
          this.thereIsImage = true;
          this.detailAttribute.image = e.target.result;
          this.form.logoTemp = new Array(e.target.result);
        };
      }
    },

    deleteImageUpload() {
      this.form.logoTemp.splice(0, 1);
      this.detailAttribute.image = null;
      this.thereIsImage = false;
      this.isImageUploaded = false;
    },

    async uploadMedia(type, image) {
      return this.media.uploadMedia(type, image);
    },

    async submitProcess(
      type,
      description,
      coBrand,
      image,
    ) {
      return this.attribute.updateAttributeList(
        this.$route.params.id,
        type,
        description,
        coBrand,
        image,        
      );
    },

    async submit() {
      const validate = this.$refs.observer.validate();

      if (this.isImageUploaded) {
        this.isLoading = true;
        await this.uploadMedia('community', this.form.logoTemp);

        if (this.isErrorUploadMedia) {
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseMediaUpload,
          });
          this.isLoading = false;
          this.isErrorUploadMedia = false;
        } else {
          this.isLoading = false;
          this.form.image = this.medias[0];
        }
      }

      if (validate && !this.isErrorUploadMedia) {
        this.isLoading = true;
        await this.submitProcess(
          this.detailAttribute.type,
          this.detailAttribute.description,
          this.detailAttribute.coBrand,
          this.isImageUploaded ? this.form.image: this.detailAttribute.image,
        ); 

        if (this.isErrorUpdateAttribute) {
          this.isLoading = false;
          this.isErrorUpdateAttribute = false;
          Swal.fire({
            icon: "error",
            title: "KESALAHAN",
            text: this.errorCauseUpdateAttribute,
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            icon: "success",
            title: "Sukses",
            text: "Data berhasil dibuat",
          });
          this.isErrorUpdateAttribute = false;
          this.$refs.observer.reset();
          this.back()
        }
      }
    },
  },
};
</script>
